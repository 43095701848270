import { template as template_0f779894bc2b4ff580c48fa8cc40da4b } from "@ember/template-compiler";
const FKLabel = template_0f779894bc2b4ff580c48fa8cc40da4b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
