import { template as template_df8b6bc5395d4291b4570be21b02b7bb } from "@ember/template-compiler";
const WelcomeHeader = template_df8b6bc5395d4291b4570be21b02b7bb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
