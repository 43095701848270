import { template as template_c33dec080d8f4edfbf1bf5c7b09c42c9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c33dec080d8f4edfbf1bf5c7b09c42c9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
