import { template as template_b8477f85f0a746009100c044766d8012 } from "@ember/template-compiler";
const EmptyState = template_b8477f85f0a746009100c044766d8012(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
