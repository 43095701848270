import { template as template_be580643fdeb4a51b60ecf927a3866b8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_be580643fdeb4a51b60ecf927a3866b8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
