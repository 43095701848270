import { template as template_f524215a29874a15bffecbb3c4832ef9 } from "@ember/template-compiler";
import { eq } from "truth-helpers";
const FKControlConditionalContentItem = template_f524215a29874a15bffecbb3c4832ef9(`
  {{#if (eq @name @activeName)}}
    <div class="form-kit__conditional-display-content">
      {{yield}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
